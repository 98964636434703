import React, { useState, useEffect } from 'react';
import Link from 'next/link';

import styles from './hero.module.scss';
import SearchFilters from '../search-filters/search-filters';
import useLanguage from '../_hooks/useLanguage';
import useConfig from '../_hooks/useConfig';
import useGQL from '../_hooks/useGQL';

const Hero = () => {
	const { clientConfig } = useConfig();
	const { t } = useLanguage();
	const [cnt, setCnt] = useState('...');

	// Load
	const { query } = useGQL('auction>items_cnt');
	const { isFetching, isSuccess, data } = query();

	useEffect(() => {
		if (isFetching === false && isSuccess === true) {
			if (data && data.request) {
				setCnt(data.request.cnt);
			}
		}
	}, [isFetching]);

	return (
		<div className={styles.default}>
			<div className={styles.overlay} />
			<div className="container">
				<div className="row">
					<div className={`col-12 ${styles.heroWrapper}`}>
						<div className={styles.textWrapper}>
							<h1>{t('heroTitle')}</h1>
							<p>{t('heroDescription')}</p>
						</div>
						{clientConfig('LANDING_HERO_SEARCH') && <SearchFilters variation="hero" isTags={false} />}
						<Link href="/drazbe"><a className="aa">{t('heroSeeAllAuctions').replace('{cnt}', cnt)} <i className="icon arrow-right-chevron white" /></a></Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
